import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import TextField from './text-field';
import { 
  LocalizationProvider,
  DatePicker as MuiDatePicker
} from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';

export default function DatePicker(props){
  const { label, value, id, dataTestId, size='small', onChange, required, disableFuture, ...muiProps } = props;
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='body2' paddingBottom={0.5} fontWeight='medium'>
        {`${label}${required ? '*' : ''}`}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
        <MuiDatePicker
          disableFuture={disableFuture}
          required={required}
          value={value}
          onChange={onChange}
          size={size}
          id={id}
          renderInput={(params) => <TextField  
            dataTestId={dataTestId}
            id={id}
            InputLabelProps={{ shrink: true }}
            required={required}
            placeholder='DD/MM/YYYY'
            {...muiProps}
            {...params}
            size={size} />}
          {...muiProps}
        />
      </LocalizationProvider>
    </Box>
  );
}