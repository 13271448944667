import React, { createContext, useEffect, useState } from 'react';

import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { isNative } from '@helpers/device';
import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
export const AppContext = createContext();

export function AppProvider(props){
  const [openUrl, setOpenUrl] = useState(null);
  const [latestOpenUrl, setLatestOpenUrl] = useState(null);
  const [accessCode, setAccessCode] = useState(false);
  const { handleRedirectCallback } = useAuth0();
  
  const logOpenEvent = (name) => {
    mixpanel.track(name, {
      deviceType: isNative() ? 'Mobile' : 'Web'
    });
  };

  const checkForAccessCode = async (url) => {
    if(url){
      const urlParts = url.split('?');
      const searchParams = new URLSearchParams(urlParts[urlParts.length - 1]);
      if(searchParams.has('brace_access_code')){
        logOpenEvent(`App:OpenWithCode:${searchParams.get('brace_access_code')}`);
        setAccessCode(searchParams.get('brace_access_code'));
      } 
    }else{
      setAccessCode(false);
    }
  };

  const appOpenUrlHandler = async ({ url }) => {
    if(url){
      setLatestOpenUrl(url);
      checkForAccessCode(url);
    }

    if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
      await handleRedirectCallback(url);
      await Browser.close();
    }
    else if(url?.startsWith('com.bracesocial.app://') == false){
    // No-op on Android
      await Browser.close();
    }
  };

  useEffect(() => {
    App.addListener('appUrlOpen', appOpenUrlHandler);
  }, [handleRedirectCallback]);

  const checkOpenUrl = async () => {
    const { url } = await App.getLaunchUrl();
    if(url){
      setOpenUrl(url);
      checkForAccessCode(url);
    }

    App.addListener('appUrlOpen', appOpenUrlHandler);
  };

  const checkLocation = async () => {
    checkForAccessCode(window.location.href);
  };

  useEffect(() => {
    if(isNative()){
      checkOpenUrl();
    }else{
      checkLocation();
    }
    
  }, []);

  return (
    <AppContext.Provider value={{ 
      latestOpenUrl: latestOpenUrl ?? openUrl,
      accessCode,
      clearAccessCode: () => setAccessCode(false)
    }}>
      {props.children}
    </AppContext.Provider>
  );
}