import React from 'react';

import Icon from './icons';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

export default function IconWithBackground(props){
  const theme = useTheme();
  var { isLoading, 
    type, 
    shape='square',
    stroke=theme.palette.primary.main, 
    fill='#fff', 
    backgroundColor= theme.palette.primary.main , 
    size=32 
  } = props;

  return (
    <Box sx={{ 
      backgroundColor: backgroundColor, 
      width: size, 
      minWidth: size,
      height: size, 
      borderRadius: shape == 'round' ? '50%' : 2, 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Icon
        isLoading={isLoading}
        type={type}
        fill={fill}
        stroke={stroke}
        size={size * 0.6} />
    </Box>
  );
}