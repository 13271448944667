import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { 
  Drawer,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { UserContext } from '@context';
import { DialogContext } from '@context/dialogs';
import { EventsContext } from '@context/events';
import { Divider, Icon } from '@components/v2/atoms';
import { BillingContext } from '@context/billing';

export default function MenuDrawer(props){
  const COLOR = '#8C9593';
  const ICON_SIZE = 24;
  const { isOpen, close } = props;
  const { user, handleLogout } = useContext(UserContext);
  const { subscription } = useContext(BillingContext);
  const { trackEvent } = useContext(EventsContext);
  const { openFeedbackDialog, openSurveyDialog } = useContext(DialogContext);
  const history = useHistory();

  const goTo = (location) => {
    close();
    history.push(location);
  };

  const signOut = () => {
    trackEvent('User:LogOut');
    handleLogout(() => {
      history.push('/index');
    });
  };

  const openFeedbackSurvey = () => {
    close();
    openSurveyDialog();
  };

  return (

    <Drawer open={isOpen} onClose={close}>
      <Box 
        sx={{  
          height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
          marginTop: 'env(safe-area-inset-top)',
          display: 'flex',
          flexDirection: 'column',
          color: COLOR,
          fontWeight: 'medium',
          width:250
        }}
      >
        <Box flexGrow={1}>
          <ListItem onClick={() => goTo('/profile')} data-testid='menu-drawer-profile-button'>
            <ListItemIcon>
              <Icon type='user'
                size={ICON_SIZE}
                fill={COLOR} 
                stroke={'none'} />

            </ListItemIcon>
            <ListItemText primary={'Profile'} />
          </ListItem>
          <ListItem onClick={() => goTo('/trophies')} data-testid='menu-drawer-trophies-button'>
            <ListItemIcon>
              <Icon type='trophy'
                size={ICON_SIZE}
                fill={COLOR} 
                stroke={'#fff'} />

            </ListItemIcon>
            <ListItemText primary={'Trophies'} />
          </ListItem>
          <ListItem onClick={() => openFeedbackDialog()}>
            <ListItemIcon>
              <Icon type='comment'
                size={ICON_SIZE}
                fill={COLOR} 
                stroke={'#fff'} />

            </ListItemIcon>
            <ListItemText primary={'Support Chat'} />
          </ListItem>
          <ListItem button
            component="a"
            href="https://www.bracesocial.com/blog"
            target="_blank">
            <ListItemIcon>
              <Icon type='blog'
                size={ICON_SIZE}
                fill={COLOR} 
                stroke={COLOR} />

            </ListItemIcon>
            <ListItemText primary={'Brace Blog'} />
          </ListItem>

          {subscription?.isTrial &&
            <ListItem button onClick={openFeedbackSurvey} to='/settings'>
              <ListItemIcon>
                <Icon type='chat'
                  size={ICON_SIZE}
                  fill={COLOR} 
                  stroke={COLOR} />
              </ListItemIcon>
              <ListItemText primary={'Feedback Form'} />
            </ListItem>
          }
        </Box>
        <Box>
          <ListItem button component={Link} to='/settings'>
            <ListItemIcon>
              <Icon type='settings'
                size={ICON_SIZE}
                fill={COLOR} 
                stroke={'none'}  />

            </ListItemIcon>
            <ListItemText primary={'Settings'} />
          </ListItem>
          <Divider />
          <ListItem onClick={signOut} data-testid='menu-drawer-button-log-out'>
            <ListItemIcon>
              <Icon type='log-out'
                size={ICON_SIZE}
                fill={COLOR} 
                stroke={'none'} />

            </ListItemIcon>
            <ListItemText primary={'Log Out'} />
          </ListItem>
        </Box>
      </Box>
    </Drawer>
  );
}